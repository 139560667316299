<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Below are the chemical reactions described in the lab manual. Enter the product(s) of each
        reaction in the corresponding box. Be sure to balance each equation and include the
        coefficient and phase in your response.
      </p>

      <p class="mb-4">a) Dissociation of the metal carbonate in water:</p>

      <p class="mb-5 pl-12">
        <stemble-latex
          content="$\ce{M2CO3(s) ->~~} \underline{\hspace{0.413cm}\text{A}\hspace{0.413cm}} + \underline{\hspace{0.413cm}\text{B}\hspace{0.413cm}}$"
        />
      </p>

      <chemical-notation-input
        v-model="inputs.rxn1Product1"
        dense
        prefix="A:"
        :show-note="false"
        class="mb-2"
        :disabled="!allowEditing"
      />

      <chemical-notation-input
        v-model="inputs.rxn1Product2"
        dense
        prefix="B:"
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-4">b) Precipitation of calcium carbonate:</p>

      <p class="mb-5 pl-12">
        <stemble-latex
          content="$\ce{Ca^2+(aq) + CO3^{2-}(aq) ->~~} \underline{\hspace{0.413cm}\text{C}\hspace{0.413cm}}$"
        />
      </p>

      <chemical-notation-input
        v-model="inputs.rxn2Product1"
        dense
        prefix="C:"
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-4">c) Overall Reaction:</p>

      <p class="mb-5 pl-12">
        <stemble-latex
          content="$\ce{CaCl2(aq) + M2CO3(s) ->~~} \underline{\hspace{0.413cm}\text{D}\hspace{0.413cm}} + \underline{\hspace{0.413cm}\text{E}\hspace{0.413cm}}$"
        />
      </p>

      <chemical-notation-input
        v-model="inputs.rxn3Product1"
        dense
        prefix="D:"
        :show-note="false"
        class="mb-2"
        :disabled="!allowEditing"
      />

      <chemical-notation-input
        v-model="inputs.rxn3Product2"
        dense
        prefix="E:"
        :show-note="false"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'GravimetricAnalysisMetalCarbonate_PrelabChemicalEquations',
  components: {
    ChemicalNotationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rxn1Product1: null,
        rxn1Product2: null,
        rxn2Product1: null,
        rxn3Product1: null,
        rxn3Product2: null,
      },
    };
  },
};
</script>
